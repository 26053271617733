import SliderGallery_SliderGalleryDefaultSkinComponent from '@wix/thunderbolt-elements/src/components/NativeGalleries/SliderGallery/viewer/skinComps/SliderGalleryDefaultSkin/SliderGalleryDefaultSkin.skin';


const SliderGallery_SliderGalleryDefaultSkin = {
  component: SliderGallery_SliderGalleryDefaultSkinComponent
};


export const components = {
  ['SliderGallery_SliderGalleryDefaultSkin']: SliderGallery_SliderGalleryDefaultSkin
};

